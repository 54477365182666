import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  StackItem,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiVenueSummary } from "@operations-hero/lib-api-client";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Pager } from "../../../components/pagination/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { AccountFormProps } from "../AccountForm";
import { ExternalVenueModal } from "./ExternalVenueModal";
import { MapExternalVenueModal } from "./MapExternalVenueModal";

const PAGE_SIZE = 10;
export const ExternalVenuesList: FC<AccountFormProps> = ({ account }) => {
  const { apiClient } = useAuthentication();

  const showToast = useShowToast();

  const [venues, setVenues] = useState<ApiVenueSummary[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [workingVenue, setWorkingVenue] = useState<ApiVenueSummary>();

  const headerBg = useColorModeValue("gray.100", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenMapVenue,
    onOpen: onOpenMapVenue,
    onClose: onCloseMapVenue,
  } = useDisclosure();

  const onPageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onClickWorkingVenue = useCallback(
    async (sub: ApiVenueSummary) => {
      setWorkingVenue(sub);
      onOpen();
    },
    [onOpen]
  );

  const closeModal = useCallback(() => {
    setWorkingVenue(undefined);
    onClose();
  }, [onClose]);

  useEffect(() => {
    apiClient
      .findVenues(account.id, {
        page,
        pageSize: PAGE_SIZE,
        hasBigTeams: true,
      })
      .then((pagedVenues) => {
        setVenues(pagedVenues.data);
        setTotal(pagedVenues.total);
      });
  }, [apiClient, account, page]);

  const handleRemoveVenue = useCallback(
    (venue: ApiVenueSummary) => {
      if (venue) {
        apiClient
          .updateVenue(account.id, venue.id, { bigTeamsSchoolId: null })
          .then(() => {
            showToast("success", "Subscription was deactivated successfully");
            apiClient
              .findVenues(account.id, {
                page,
                pageSize: PAGE_SIZE,
                hasBigTeams: true,
              })
              .then((pagedVenues) => {
                setVenues(pagedVenues.data);
                setTotal(pagedVenues.total);
              });
          })
          .catch(() => {
            showToast("error", "Something happened while updating venue");
          });
      }
    },
    [account.id, apiClient, page, showToast]
  );
  const onMapExternalVenueModalCallBac = useCallback(async () => {
    try {
      const { data, total } = await apiClient.findVenues(account.id, {
        page,
        pageSize: PAGE_SIZE,
        hasBigTeams: true,
      });
      setVenues(data);
      setTotal(total);
    } catch {
      showToast("error", "Something happened while updating venue");
    }
  }, [account.id, apiClient, page, showToast]);

  return (
    <Box>
      <HStack py={4} justifyContent="space-between">
        <Box>
          <Heading mb={2} size="lg">
            External Venues
          </Heading>
        </Box>
        <Button
          size="sm"
          colorScheme="blue"
          onClick={() => {
            onOpenMapVenue();
          }}
        >
          Map new venue
        </Button>
      </HStack>

      <HStack
        p={2}
        w="100%"
        gap="4px"
        spacing={0}
        flexDir="row"
        flexWrap="wrap"
        bgColor={headerBg}
        alignItems="center"
        d={["none", "none", "flex"]}
      >
        <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
          Name
        </StackItem>
        <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
          External Id
        </StackItem>
        <StackItem flex={1} fontWeight="bold">
          Association
        </StackItem>
        <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
          Actions
        </StackItem>
      </HStack>
      {venues.map((venue, idx) => (
        <Fragment key={venue.id + idx}>
          <HStack
            py={[2, 2, 4]}
            w="100%"
            gap="4px"
            spacing={0}
            flexDir="row"
            flexWrap="wrap"
            cursor="pointer"
            alignItems="center"
            onClick={() => onClickWorkingVenue(venue)}
          >
            <StackItem w={["calc(20% - 4px)"]} order={1} isTruncated>
              {venue.name}
            </StackItem>

            <StackItem
              w={["calc(20% - 4px)"]}
              order={2}
              textAlign={["right", "right", "left"]}
            >
              {venue.bigTeamsSchoolId}
            </StackItem>

            <StackItem
              flex={1}
              order={[3, 3, 2]}
              textAlign={["right", "right", "left"]}
            >
              Big Teams
            </StackItem>
            <StackItem w={["calc(20% - 4px)"]} order={[2, 2, 3]}>
              <Button
                size="xs"
                variant="outline"
                colorScheme="red"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveVenue(venue);
                }}
              >
                Remove Venue
              </Button>
            </StackItem>

            <Divider order={5} />
          </HStack>
        </Fragment>
      ))}
      {isOpen && workingVenue && (
        <ExternalVenueModal
          isOpen={isOpen}
          onClose={closeModal}
          workingVenue={workingVenue}
          accountId={account.id}
        />
      )}
      <Pager
        currentPage={page}
        totalPages={Math.ceil(total / PAGE_SIZE)}
        onPageChange={onPageChange}
      />
      {isOpenMapVenue && (
        <MapExternalVenueModal
          isOpen={isOpenMapVenue}
          onClose={onCloseMapVenue}
          accountId={account.id}
          onCloseCallBack={onMapExternalVenueModalCallBac}
        />
      )}
    </Box>
  );
};
