import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ApiVenueSummary } from "@operations-hero/lib-api-client";
import { FC, useCallback, useState } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { VenueAutocomplete } from "../../../components/selects/VenueAutocomplete";
import { useShowToast } from "../../../hooks/showToast";

interface SubscriptionFormProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: string;
  onCloseCallBack: () => Promise<void>;
}

export const MapExternalVenueModal: FC<SubscriptionFormProps> = ({
  isOpen,
  onClose,
  accountId,
  onCloseCallBack,
}) => {
  const { apiClient } = useAuthentication();
  const showToast = useShowToast();

  const [venue, setVenue] = useState<ApiVenueSummary | null>(null);
  const [schoolId, setSchoolId] = useState<string | undefined>("");

  const [formErrors, setFormErrors] = useState({
    venue: false,
    schoolId: false,
  });

  const handleOnChangeTargetVenue = useCallback(
    (value: ApiVenueSummary | null) => {
      setVenue(value);

      const venueError = value === null;
      setFormErrors({ ...formErrors, venue: venueError });
    },
    [formErrors]
  );

  const checkErrors = useCallback(() => {
    const venueError = venue === null;
    const schoolIdError = !schoolId;
    setFormErrors({ venue: venueError, schoolId: schoolIdError });
    return venueError || schoolIdError;
  }, [schoolId, venue]);

  const handleClickSave = useCallback(async () => {
    const hasErrors = checkErrors();

    if (!hasErrors && venue !== null && schoolId) {
      try {
        await apiClient.updateVenue(accountId, venue.id, {
          bigTeamsSchoolId: schoolId,
        });
        showToast("success", "School Id added successfully");
      } catch {
        showToast("error", "Something happened while updating the venue");
      } finally {
        onClose();
        await onCloseCallBack();
      }
    }
  }, [
    accountId,
    apiClient,
    checkErrors,
    onClose,
    onCloseCallBack,
    schoolId,
    showToast,
    venue,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold">Map external Venue</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w={"full"} alignItems={"start"} gap={2}>
            <HStack w={"full"}>
              <Text minW={16}>Venue:</Text>
              <FormControl isInvalid={formErrors.venue}>
                <Box w={"full"}>
                  <VenueAutocomplete
                    placeholder="Select the venue"
                    value={venue}
                    onChange={handleOnChangeTargetVenue}
                    accountId={accountId}
                  />
                </Box>
                <FormErrorMessage>You must select a venue</FormErrorMessage>
              </FormControl>
            </HStack>

            <HStack w={"full"}>
              <Text minW={16}>BT id:</Text>
              <FormControl isInvalid={formErrors.schoolId}>
                <Input
                  value={schoolId}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSchoolId(value);
                    const schoolIdError = value ? false : true;
                    setFormErrors({ ...formErrors, schoolId: schoolIdError });
                  }}
                />
                <FormErrorMessage>The id is required</FormErrorMessage>
              </FormControl>
            </HStack>
            <HStack w={"full"} justifyContent={"center"} pt={4} gap={4}>
              <Button onClick={() => onClose()}>Cancel</Button>
              <Button onClick={handleClickSave}>Save</Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
