import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import {
  ApiVenueSummary,
  UpdateApiVenue,
} from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { useShowToast } from "../../../hooks/showToast";

const VenueSchema = yup.object().shape({
  bigTeamsSchoolId: yup.string().required("School id is required"),
});

export interface ExternalVenueModalProps {
  isOpen: boolean;
  onClose: () => void;
  workingVenue: ApiVenueSummary;
  accountId: string;
}

const initialValues: UpdateApiVenue = {
  bigTeamsSchoolId: undefined,
};

export const ExternalVenueModal: React.FC<ExternalVenueModalProps> = ({
  isOpen,
  onClose,
  workingVenue,
  accountId,
}) => {
  const { apiClient } = useAuthentication();
  const showToast = useShowToast();

  const [venue, setVenue] = useState<UpdateApiVenue>();

  const handleOnClose = useCallback(() => {
    setVenue(initialValues);
    onClose();
  }, [onClose]);

  const handleOnSubmit = useCallback(
    async (values: UpdateApiVenue) => {
      try {
        await apiClient.updateVenue(accountId, workingVenue.id, {
          bigTeamsSchoolId: values.bigTeamsSchoolId,
        });
        showToast("success", "School Id added successfully");
        workingVenue.bigTeamsSchoolId = values.bigTeamsSchoolId
          ? values.bigTeamsSchoolId
          : workingVenue.bigTeamsSchoolId;
        handleOnClose();
      } catch {
        showToast("error", "Something happened while updating the venue");
      }
    },
    [accountId, apiClient, handleOnClose, showToast, workingVenue]
  );

  useEffect(() => {
    setVenue({
      bigTeamsSchoolId: workingVenue.bigTeamsSchoolId
        ? workingVenue.bigTeamsSchoolId
        : undefined,
    });
  }, [workingVenue, setVenue]);

  return (
    <>
      {venue ? (
        <Modal isOpen={isOpen} onClose={handleOnClose} closeOnEsc={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{"Add School Id"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box w="100%">
                <Formik
                  initialValues={venue}
                  validationSchema={VenueSchema}
                  onSubmit={handleOnSubmit}
                >
                  {({ values, isValid }) => {
                    return (
                      <Form>
                        <VStack gap={5}>
                          <TextInputControl
                            name="bigTeamsSchoolId"
                            label="BigTeams School Id"
                            value={
                              values.bigTeamsSchoolId
                                ? values.bigTeamsSchoolId
                                : null
                            }
                          />
                          <HStack flexGrow={1}>
                            <Button onClick={handleOnClose}>Cancel</Button>
                            <Button
                              float="right"
                              type="submit"
                              isDisabled={!isValid}
                            >
                              Save
                            </Button>
                          </HStack>
                        </VStack>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
};
